<script setup>
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import { ref, watch, onMounted, onUnmounted } from 'vue';
import '@tato30/vue-pdf/style.css'

const props = defineProps({
  pdfUrl: {
    type: String,
    required: true
  },
  startPage: {
    type: Number,
  },
   endPage: {
    type: Number,
  },
  hidePdf: {
    type: Function,
  },
  highlightText: {
    type: String,
    default: ''
  }
});

const { pdf } = usePDF(props.pdfUrl)
const pageNum = ref(props.startPage)
const highlights = ref(props.highlightText)
const containerHeight = ref(500) // Default height in pixels
const isDragging = ref(false)
const startY = ref(0)
const startHeight = ref(0)

watch(() => props.startPage, (val) => {
  pageNum.value = val
})

watch(() => props.highlightText, (val) => {
  highlights.value = val
})

const handleMouseDown = (e) => {
  e.preventDefault()
  isDragging.value = true
  startY.value = e.clientY
  startHeight.value = containerHeight.value
  document.body.style.cursor = 'ns-resize'
  document.body.classList.add('dragging')
}

const handleMouseMove = (e) => {
  if (!isDragging.value) return
  
  // Moving up (negative diff) should increase height
  // Moving down (positive diff) should decrease height
  const diff = startY.value - e.clientY
  const newHeight = Math.min(
    window.innerHeight * 0.8, // max 80vh
    Math.max(200, startHeight.value + diff) // min 200px
  )
  
  containerHeight.value = newHeight
}

const handleMouseUp = () => {
  isDragging.value = false
  document.body.style.cursor = 'default'
  document.body.classList.remove('dragging')
}

onMounted(() => {
  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp)
})

onUnmounted(() => {
  document.removeEventListener('mousemove', handleMouseMove)
  document.removeEventListener('mouseup', handleMouseUp)
})
</script>

<template>
  <div class="pdf-viewer" :style="{ height: `${containerHeight}px` }">
    <!-- Drag handle -->
    <div 
      class="resize-handle"
      @mousedown="handleMouseDown"
    >
      <div class="drag-indicator"></div>
    </div>

    <div class="sticky-top d-flex justify-content-end" style="top:0;z-index:1000">
      <a href="javascript:void(0)" @click="hidePdf"><i class="fas fa-times p-4"></i></a>
    </div>

    <div class="content-container">
      <object
        :data="pdfUrl"
        type="application/pdf"
        width="100%"
        :height="`${containerHeight - 50}px`"
      >
        <p>Your web browser doesn't have a PDF plugin. Instead you can <a :href="pdfUrl">click here to download the PDF file.</a></p>
      </object>
    </div>
  </div>
</template>

<style scoped>
.pdf-viewer {
  width: 100%;
  position: relative;
  transition: height 0.05s ease-out;
}

.content-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  cursor: ns-resize;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.drag-indicator {
  width: 40px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  transition: background-color 0.2s;
}

.resize-handle:hover .drag-indicator {
  background-color: #bdbdbd;
}

/* Add this to your global styles or parent component */
.dragging {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>