<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import Multiselect from "@vueform/multiselect";
import ScopeItemRow from "../../components/ScopeItemRow.vue";
import {
  schedule3sections,
  scopeCodeOptions,
  scopeItemsDefault,
  scopeItemUnitTypes,
  scopeSections,
  scopeTradeOptions,
  toCurrency,
} from "../../assets/js/helpers";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import setTooltip from "@/assets/js/tooltip.js";
import PDFVuer from "./PDFVuer.vue";

// Props
const props = defineProps({
  propertyId: {
    type: String,
    required: true,
  },
  propertyMetadata: {
    type: Object,
  },
  contractId: {
    type: String,
    required: true,
  },
  address: {
    type: String,
    required: true,
  },
  loadContract: {
    type: Function,
  },
  contractStatus: {
    type: Number,
  },
  scopeFileUrl: {
    type: String,
  },
  setActions: {
    type: Function,
  },
});

// Reactive references
const loading = ref(false);
const scopeObj = ref({});
const unsavedChanges = ref(false);
const showPdf = ref(false);
const currentSection = ref("");

const section2Subitems = ref([]);

// Vuex store and toast notifications
const store = useStore();
const toast = useToast();

// API endpoint and authorization headers
const apiEndpoint = store.state.apiEndpoint;
const token = sessionStorage.getItem("token");
const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

// Disable editing if contract status is not 1
const disabledEdit = props.contractStatus !== 1;

// Default item structure
const defaultItem = {
  item: "",
  qty: "",
  unit: "",
  cost: "",
  item_code: "",
};

// Fetch scope data on component mount
onMounted(async () => {
  await getScope();
  setTooltip();
  props.setActions({
    save: handleSave,
    reset: handleReset,
    print: handleDownloadScopePDF,
  });
  populateSubitems();

});

// Watch for changes in propertyId to refresh scope data
watch(
  () => props.propertyId,
  async (newVal) => {
    if (newVal) {
      await getScope();
      // Add this line to adjust textareas after property ID changes
      adjustAllTextareas();
    }
  }
);

const cleanupTextFormat = (text) => {
  if (!text) return "";
  // Replace multiple newlines/spaces with a single space
  return text
    .replace(/[\n\r]+/g, " ") // Replace newlines with space
    .replace(/\s+/g, " ") // Replace multiple spaces with single space
    .trim(); // Remove leading/trailing spaces
};

/**
 * Fetch the scope data for the property
 */
const getScope = async () => {
  loading.value = true;
  try {
    const response = await axios.get(
      `${apiEndpoint}/property/get-scope/${props.propertyId}`,
      headers
    );
    if (Object.keys(response.data.scope).length !== 0) {
      // Assign scope
      const cleanedScope = {};
      Object.entries(response.data.scope).forEach(([sectionId, section]) => {
        cleanedScope[sectionId] = {
          ...section,
          items: section.items.map((item) => ({
            ...item,
            item: cleanupTextFormat(item.item),
          })),
        };
      });

      // Assign cleaned scope
      scopeObj.value = cleanedScope;

      // Recalculate totals for all sections after loading
      Object.keys(scopeObj.value).forEach((sectionId) => {
        calculateSectionTotal(sectionId);
      });
      adjustAllTextareas();
    } else {
      // If no scope found then set to default
      scopeObj.value = scopeItemsDefault;
    }
  } catch (error) {
    toast.error("Failed to retrieve scope data");
    console.log("Error:", error);
  } finally {
    loading.value = false;
  }
};

/**
 * Save the current scope data
 */
const handleSave = async () => {
  loading.value = true;
  try {
    await axios.put(
      `${apiEndpoint}/property/save-scope/${props.propertyId}/`,
      {
        scope: scopeObj.value,
      },
      headers
    );
    toast.success("Property scope saved successfully");
    unsavedChanges.value = false;
    await getScope();
    await props.loadContract();
  } catch (error) {
    console.error(error);
    toast.error("An error occurred while saving property scope");
  } finally {
    loading.value = false;
  }
};

/**
 * Add a new item to a section
 * @param {String} sectionId - The ID of the section
 */
const handleAddItem = (sectionId) => {
  if (scopeObj.value[sectionId]) {
    scopeObj.value[sectionId]?.items.push({ ...defaultItem });
  } else {
    scopeObj.value[sectionId] = {
      items: [{ ...defaultItem }],
    };
  }
  unsavedChanges.value = true;
};

/**
 * Handle changes to a cell in the scope table
 * @param {String} sectionId - The ID of the section
 * @param {Number} itemIndex - The index of the item
 * @param {String} key - The key of the item property
 * @param {String|Number} value - The new value for the item property
 */
const handleCellChange = (sectionId, itemIndex, key, value, pressedKey=null) => {
  // Special handling for trade_code which is now an array
  if (key === "trade_code") {
    scopeObj.value[sectionId].items[itemIndex][key] = Array.isArray(value)
      ? value
      : [value];
  } else {
    // Handle other fields as before
    scopeObj.value[sectionId].items[itemIndex][key] =
      key === "cost" ? Number(value).toFixed(2) : value;
  }

  // If the changed cell is qty or cost, recalculate section total
  if (key === "cost") {
    if(pressedKey === "Enter") {
      alert("ENTER")
    }
    calculateSectionTotal(sectionId);
  }
  unsavedChanges.value = true;
};

/**
 * Delete an item from a section
 * @param {String} sectionId - The ID of the section
 * @param {Number} itemIndex - The index of the item
 */
const handleDeleteItem = (sectionId, itemIndex) => {
  scopeObj.value[sectionId].items.splice(itemIndex, 1);
  calculateSectionTotal(sectionId);
  unsavedChanges.value = true;
};

/**
 * Calculate the total cost for a section
 * @param {String} sectionId - The ID of the section
 */
const calculateSectionTotal = (sectionId) => {
  if (!scopeObj.value[sectionId] || !scopeObj.value[sectionId].items) {
    scopeObj.value[sectionId] = {
      items: [],
      total: 0.0,
      sectionSubtotal: 0.0,
    };
    return;
  } else if (!scopeObj.value[sectionId].total) {
    scopeObj.value[sectionId].total = 0.0;
  } else if (!scopeObj.value[sectionId].sectionSubtotal) {
    scopeObj.value[sectionId].sectionSubtotal = 0.0;
  }

  // Calculate total by multiplying qty and cost for each item and summing
  const total = scopeObj.value[sectionId].items.reduce((acc, item) => {
    const cost = Number(item.cost) || 0; // Ensure cost is a number
    return acc + cost;
  }, 0);

  // Calculate section subtotal which is the addition of the cost of all items that do not have a 'subitem' value in them
  const sectionSubtotal = scopeObj.value[sectionId].items
    .filter((item) => !item.subitem)
    .reduce((acc, item) => {
      const cost = Number(item.cost) || 0; // Ensure cost is a number
      return acc + cost;
    }, 0);

  // Update the total and section subtotal for this section
  scopeObj.value[sectionId].total = Number(total.toFixed(2));
  scopeObj.value[sectionId].sectionSubtotal = Number(sectionSubtotal.toFixed(2));

};

/**
 * Reset the scope data to default
 */
const handleReset = () => {
  scopeObj.value = scopeItemsDefault;
  unsavedChanges.value = true;
};

/**
 * Download the scope as a PDF
 */
const handleDownloadScopePDF = async () => {
  loading.value = true;
  try {
    const response = await axios.get(
      `${store.state.apiEndpoint}/property/download_scope/${props.propertyId}/`,
      {
        ...headers,
        responseType: "arraybuffer",
      }
    );

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create an Object URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `scope_${props.address}.pdf`);
    document.body.appendChild(link);
    link.click();

    // Clean up the Object URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
    toast.error("An error occurred while downloading Schedule 2");
  } finally {
    loading.value = false;
  }
};

/**
 * Set the current section for PDF viewing
 * @param {String} sectId - The ID of the section
 */
const setSection = (sectId) => {
  const section = props.propertyMetadata?.sections[sectId];
  currentSection.value = section;
  showPdf.value = true;
};

/**
 * Adjust the height of the textarea to fit its content
 * @param {Event} event - The input event
 */
const adjustTextareaHeight = (event) => {
  const textarea = event.target;
  textarea.style.height = "auto";
  textarea.style.height = `${textarea.scrollHeight}px`;
};

// Add this new function to handle all textareas
const adjustAllTextareas = () => {
  // Wait for next tick to ensure DOM is updated
  nextTick(() => {
    // Get all textareas in the scope
    const textareas = document.querySelectorAll(".scope-section-row textarea");
    textareas.forEach((textarea) => {
      // Reset height to auto first
      textarea.style.height = "auto";
      // Set to scrollHeight to match content
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  });
};


// Populate subitems for sections 2 and 3
const populateSubitems = () => {
  // Iterate through scope sections and add items to section2Subitems
  scopeSections.forEach((section) => {
    if (!section.items) return;
    section.items.forEach((item) => {
      section2Subitems.value.push(item);
    });
  });

};




const removeTag = (sectionId, itemIndex, tagToRemove) => {
  const currentTags = scopeObj.value[sectionId].items[itemIndex].trade_code;
  const updatedTags = currentTags.filter((tag) => tag !== tagToRemove);
  handleCellChange(sectionId, itemIndex, "trade_code", updatedTags);
};

const handleNextCell = (scopeItemIndex, scopeSectIdx) => {

  const findAndFocusNextCell = (sectIdx, itemIdx) => {
    const nextItemIndex = itemIdx + 1;
    const nextItem = scopeObj.value[scopeSections[sectIdx].id]?.items[nextItemIndex];

    if (nextItem) {
      const nextElementId = `cost-${nextItemIndex}-for-${sectIdx}`;
      const nextElement = document.getElementById(nextElementId);
      console.log("Next Element ID:", nextElementId);
      if (nextElement) {
        nextElement.focus();
        return;
      }
    }

    const nextSectionIndex = sectIdx + 1;
    if (nextSectionIndex < Object.keys(scopeObj.value).length) {
      findAndFocusNextCell(nextSectionIndex, -1);
    }
  };

  findAndFocusNextCell(scopeSectIdx, scopeItemIndex);
};
</script>

<template>
  <LoadingSpinner v-if="loading" message="Loading..." :fullScreen="true" />

  <div>
    <div class="scope-header d-flex justify-content-between">
      <div>
        <div class="scope-header-div">
          <h1 class="scope-h1 col-md-12">
            Capital Improvement Scope of Work: Dwelling
          </h1>
        </div>
        <h5 class="fwt-bold scope-h5">Work Detail by Section: GST Exclusive</h5>
      </div>
    </div>
    <div class="property-scope">
      <div class="row col-md-12">
        <p class="p-0 mb-0 fwt-bold">Works Contract ID:</p>
        <span class="gray-cell col-6 ms-0 ps-0">{{ contractId }}</span>
        <div class="pt-3"></div>
        <p class="p-0 mb-0 fwt-bold">Address:</p>
        <span class="gray-cell col-6 ms-0 ps-0">{{ address }}</span>
      </div>
    </div>
    <div class="scope-table my-3 row">
      <div v-for="(scopeSect, scopeSectIdx) in scopeSections" :key="scopeSect.id">
        <div class="scope-section-row row col-12 mt-3">
          <div
            class="scope-section-code border border-dark border-1 col-1 text-center fwt-bold"
          >
            {{ scopeSect.code }}
          </div>

          <div
            class="scope-section-code border border-dark border-1 col-5 fwt-bold gray-cell p-0"
          >
            {{ scopeSect.title }}
          </div>
          <!-- <div class="col-1 p-0 ms-2">
            <button
              v-if="propertyMetadata?.sections?.[scopeSect.id]?.page"
              data-bs-toggle="tooltip"
              title="Click to view section in the Scope Document"
              class="btn btn-sm btn-link p-0 m-0"
              @click="setSection(scopeSect.id)"
            >
              <i class="fas fa-bookmark"></i>
            </button>
          </div> -->
        </div>
        <div
          class="scope-section-row row col-12 mt-3 d-flex align-items-center"
        >
          <ScopeItemRow
            v-for="(colSize, index) in ['1', '2', '6', '1', '1', '2']"
            :key="index"
            :colSize="colSize"
            :textCenter="index === 0"
          >
            <span v-if="index === 0">Code</span>
            <span v-else-if="index === 1">Trade</span>
            <span v-else-if="index === 2">Description &/or Location:</span>
            <span v-else-if="index === 3">Unit</span>
            <span v-else-if="index === 4">Qty</span>
            <span v-else>Builders Price</span>
          </ScopeItemRow>
          <div class="col-2 fwt-bold">
            <span>Related EESHP </span>
          </div>
        </div>
        <div
          class="d-flex gap-1 align-items-center"
          v-for="(scopeItem, scopeItemIndex) in scopeObj[scopeSect.id]?.items"
          :key="scopeItem.id"
        >
          <div
            :class="
              scopeItem.item_code?.toLowerCase() === 'eep' ? 'is-eep' : ''
            "
            class="scope-section-row row col-12"
          >
            <ScopeItemRow colSize="1" :textCenter="true">
              <select
                class="form-control"
                :id="`item-code-${scopeItemIndex}-code-for${scopeItem.id}`"
                :value="scopeItem.item_code"
                :disabled="disabledEdit"
                @change="
                  (event) =>
                    handleCellChange(
                      scopeSect.id,
                      scopeItemIndex,
                      'item_code',
                      event.target.value
                    )
                "
              >
                <option
                  v-for="unit in scopeCodeOptions"
                  :value="unit.value"
                  :key="unit.value"
                >
                  {{ unit.label }}
                </option>
              </select>
            </ScopeItemRow>
            <ScopeItemRow
              colSize="2"
              :textCenter="true"
              :class="scopeItem.trade_code?.length>0 ? 'has-trade' : ''"
            >
              <Multiselect
                v-model="scopeItem.trade_code"
                :options="scopeTradeOptions"
                :disabled="disabledEdit"
                mode="tags"
                :closeOnSelect="false"
                placeholder="BLANK"
                @change="
                  (value) =>
                    handleCellChange(
                      scopeSect.id,
                      scopeItemIndex,
                      'trade_code',
                      value
                    )
                "
              >
                <template #tag="{ option }">
                  <div class="multiselect-tag">
                    {{ option.label || option }}
                    <span
                      v-if="!disabledEdit"
                      class="multiselect-tag-remove"
                      @mousedown.prevent
                      @click.stop="
                        removeTag(
                          scopeSect.id,
                          scopeItemIndex,
                          option.value || option
                        )
                      "
                    >
                      ✕
                    </span>
                  </div>
                </template>
              </Multiselect>
            </ScopeItemRow>
            <ScopeItemRow colSize="6">
              <textarea
                class="form-control"
                rows="1"
                :id="`item-${scopeItemIndex}-for${scopeItem.id}`"
                :disabled="disabledEdit"
                @input="adjustTextareaHeight"
                @blur="
                  (event) =>
                    handleCellChange(
                      scopeSect.id,
                      scopeItemIndex,
                      'item',
                      event.target.value
                    )
                "
                >{{ scopeItem.item }}</textarea
              >
            </ScopeItemRow>
            <ScopeItemRow colSize="1">
              <select
                class="form-control"
                :id="`unit-${scopeItemIndex}-for${scopeItem.id}`"
                :value="scopeItem.unit?.toLowerCase()"
                :disabled="disabledEdit"
                @change="
                  (event) =>
                    handleCellChange(
                      scopeSect.id,
                      scopeItemIndex,
                      'unit',
                      event.target.value
                    )
                "
              >
                <option
                  v-for="unit in scopeItemUnitTypes"
                  :value="unit.value"
                  :key="unit.value"
                >
                  {{ unit.label }}
                </option>
              </select>
            </ScopeItemRow>
            <ScopeItemRow colSize="1">
              <input
                type="number"
                :value="scopeItem.qty"
                class="form-control"
                :id="`qty-${scopeItemIndex}-for${scopeItem.id}`"
                :disabled="disabledEdit"
                @blur="
                  (event) =>
                    handleCellChange(
                      scopeSect.id,
                      scopeItemIndex,
                      'qty',
                      event.target.value
                    )
                "
              />
            </ScopeItemRow>
            <ScopeItemRow colSize="2">
              <div class="input-group">
                <span class="input-group-text bg-light">$</span>
                <input
                  class="form-control ps-2"
                  type="number"
                  placeholder="$0.00"
                  :value="scopeItem.cost"
                  :id="`cost-${scopeItemIndex}-for-${scopeSectIdx}`"
                  :disabled="disabledEdit"
                  @change="
                    (event) =>
                      handleCellChange(
                        scopeSect.id,
                        scopeItemIndex,
                        'cost',
                        event.target.value,
                        event.key
                      )
                  "
                  @keydown.enter="handleNextCell(scopeItemIndex, scopeSectIdx)"
                  step="0.01"
                />
              </div>
            </ScopeItemRow>

            <div class="col-2">
              <div v-if="scopeItem.item_code==='EEP'" class="mt-3">
                <select
                  class="form-control"
                  :id="`schedule2-subitem-${scopeItemIndex}-for${scopeItem.id}`"
                  :disabled="disabledEdit"
                  @blur="
                    (event) =>
                      handleCellChange(
                        scopeSect.id,
                        scopeItemIndex,
                        'subitem',
                        event.target.value
                      )
                  "
                  :value="scopeItem.subitem"
                >
                  <option value="">N/A</option>
                  <option
                    v-for="item in section2Subitems"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.title }} {{ item.code == "EESHP" ? "(EESHP)" : "" }}
                  </option>
                   

                </select>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end" v-if="!disabledEdit">
            <button
              class="btn btn-link text-danger p-0 m-0 ms-1"
              @click="handleDeleteItem(scopeSect.id, scopeItemIndex)"
            >
              <i class="fas fa-trash fs-5"></i>
            </button>
          </div>
        </div>
        <div
          class="scope-section-row row col-12"
          :id="`total-for${scopeSect.id}`"
        >
          <div
            class="offset-9 col-2 border border-dark border-1 gray-cell text-end"
          >
            {{ toCurrency(scopeObj[scopeSect.id]?.total) }}
          </div>
        </div>
        <div class="scope-section-row row col-10 mt-0 ">
          <div class="d-flex justify-content-start" v-if="!disabledEdit">
            <button
              class="btn text-primary btn-link text-lg p-0 m-0 ms-3"
              @click="handleAddItem(scopeSect.id)"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="scopeFileUrl && showPdf" class="pdf-viewer pdf-fixed-bottom">
    <PDFVuer
      :pdfUrl="scopeFileUrl"
      :pageNumber="currentSection?.page"
      :hidePdf="() => (showPdf = false)"
      :highlightText="currentSection?.schedule2_title"
    />
  </div>
</template>
<style scoped>
@import "@vueform/multiselect/themes/default.css";

div,
input,
select,
textarea,
option {
  font-size: 13px;
}


/* Updated multiselect styles */
:deep(.multiselect) {
  min-height: 35px;
  font-size: 13px;
}

:deep(.multiselect-tag) {
  background: #00047B;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  margin: 2px;
}

:deep(.multiselect-tag-remove) {
  color: white;
}

:deep(.multiselect-input) {
  font-size: 13px;
}

:deep(.multiselect-dropdown) {
  font-size: 13px;
}

:deep(.multiselect-options) {
  font-size: 13px;
}

:deep(.multiselect-multiple-label) {
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 13px;
}

:deep(.multiselect-placeholder) {
  padding: 5px;
}

/* Make the dropdown menu appear above other elements */
:deep(.multiselect-dropdown) {
  z-index: 100;
}

/* Style for the selected options in the box */
:deep(.multiselect-tags) {
  margin: 0;
  padding: 2px;
}

/* Adjust the input wrapper to show full content */
:deep(.multiselect-wrapper) {
  min-height: 35px;
  display: flex;
  align-items: center;
}
:deep(.multiselect-tag) {
  background: #00047B;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  margin: 2px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px; /* Added gap between text and X */
}

:deep(.multiselect-tag-remove) {
  color: white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

:deep(.multiselect-tag-remove:hover) {
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
