<template>
  <LoadingSpinner v-if="loading" :full-screen="true" message="Downloading..." />
  <button
    href="#"
    class="btn m-0 btn-link text-info"
    :class="[showMenu ? 'show' : '']"
    id="contractSubmenu"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    @click="showMenu = !showMenu"
  >
    <i class="fa fa-file-pdf me-2"></i>
    Download
  </button>
  <ul
    class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
    :class="showMenu ? 'show' : ''"
    style="font-size: 9px !important"
    aria-labelledby="contractSubmenu"
  >
    <li class="mb-2">
      <a class="btn btn-link m-0" @click="() => downloadCombined('schedule2')">
        <div class="d-flex align-items-center gap-3 text-dark">
          <div class="my-auto">
            <i class="fas fa-table"></i>
          </div>
          <div>Tender Schedule 2</div>
        </div>
      </a>
    </li>
    <li class="mb-2">
      <a class="btn btn-link m-0" @click="downloadSchedule3">
        <div class="d-flex align-items-center gap-3 text-dark">
          <div class="my-auto">
            <i class="fas fa-dollar-sign"></i>
          </div>
          <div>Tender Schedule 3</div>
        </div>
      </a>
    </li>
    <li class="mb-2">
      <a class="btn btn-link m-0" @click="() => downloadCombined('all')">
        <div class="d-flex align-items-center gap-3 text-dark">
          <div class="my-auto">
            <i class="fas fa-folder-open"></i>
          </div>
          <div>Scope Including Schedule 2 & 3</div>
        </div>
      </a>
    </li>
    <li class="mb-2">
      <a class="btn btn-link m-0" @click="() => downloadCombined('scope')">
        <div class="d-flex align-items-center gap-3 text-dark">
          <div class="my-auto">
            <i class="fas fa-th-list"></i>
          </div>
          <div>Scope Only</div>
        </div>
      </a>
    </li>
    <li class="mb-2">
      <a class="btn btn-link m-0 col-12" @click="() => downloadCombined('files')">
        <div class="d-flex align-items-center gap-3 text-dark">
          <div class="my-auto">
            <i class="fas fa-map"></i>
          </div>
          <div>Plans, Colours, Photos & Others</div>
        </div>
      </a>
    </li>
    <li class="mb-2">
      <a class="btn btn-link m-0" :href="scopeFileUrl" target="_blank">
        <div class="d-flex align-items-center gap-3 text-dark">
          <div class="my-auto">
            <i class="fas fa-eye"></i>
          </div>
          <div>Original Tender</div>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";

import { useRouter } from "vue-router";
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";
import { useToast } from "vue-toast-notification";
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  props: {
    scopeFileUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    LoadingSpinner,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const showMenu = ref(false);

    const toast = useToast();

    const downloadSchedule3 = async () => {
      loading.value = true;
      showMenu.value = false;
      const token = sessionStorage.getItem("token");
      const contractID = router.currentRoute.value.params.id;
      try {
        // Set responseType to 'arraybuffer' to handle binary data (PDF)
        const response = await axios.get(
          `${store.state.apiEndpoint}/download_schedule3/${contractID}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create an Object URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `schedule3_${contractID}.pdf`);
        document.body.appendChild(link);
        link.click();

        // Clean up the Object URL
        window.URL.revokeObjectURL(url);
        loading.value = false;
      } catch (error) {
        toast.error("An error occurred while downloading");
        loading.value = false;
        console.error(error);
      }
    };

    const downloadCombined = async (downloadType = "all") => {
      loading.value = true;
      showMenu.value = false;
      const token = sessionStorage.getItem("token");
      const contractID = router.currentRoute.value.params.id;

      try {
        const response = await axios.get(
          `${store.state.apiEndpoint}/download_combined/${contractID}/?type=${downloadType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        // Log headers for debugging

        // Extract extension from the type parameter. Usually either "pdf" or "zip"
        let filename = `${contractID}_${downloadType}`; // Default filename
        let extension = response.headers["content-type"].includes("pdf")
          ? ".pdf"
          : ".zip";

        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.${extension}`);
        document.body.appendChild(link);
        link.click();

        // Clean up the Object URL
        window.URL.revokeObjectURL(url);
        loading.value = false;
      } catch (error) {
        loading.value = false;
        toast.error("An error occurred while downloading");
        console.error("Download error:", error);
      }
    };

    return {
      downloadSchedule3,
      downloadCombined,
      loading,
    };
  },
  mounted() {
    setTooltip();
  },
};
</script>
